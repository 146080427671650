@import "src/styles/colors";

.custom-toast-notification {
    width: 100%;
    &__title {
        font-size: 1rem;
        color: $color-white;
        font-weight: 600;
        width: 100%;
    }
    &__body {
        margin-top: 4px;
        font-size: 0.9rem;
        color: $color-white;
        font-weight: 600;
        width: 100%;
    }
    &__action {
        display: flex;
        justify-content: flex-end;
        width: 100%;

        button {
            border: none;
            color: white;
            background: linear-gradient(to bottom right, #5e5aec, #3f9efc);
            padding: 8px 16px;
            border-radius: 8px;
            cursor: pointer;
            font-weight: bold;
        }
    }
}
