body {
  font-family: system-ui, 'Helvetica Neue', Helvetica, Arial, sans-serif;
  margin: 0px;
  background: #f4f7fa;
}

@font-face {
  font-family: 'poppins-black-italic';
  src: url('assets/fonts/poppins/Poppins-BlackItalic.ttf');
}

@font-face {
  font-family: 'poppins-black';
  src: url('assets/fonts/poppins/Poppins-Black.ttf');
}

@font-face {
  font-family: 'poppins-bold';
  src: url('assets/fonts/poppins/Poppins-Bold.ttf');
}

@font-face {
  font-family: 'poppins-bold-italic';
  src: url('assets/fonts/poppins/Poppins-BoldItalic.ttf');
}

@font-face {
  font-family: 'poppins-bold-extra';
  src: url('assets/fonts/poppins/Poppins-ExtraBold.ttf');
}

@font-face {
  font-family: 'poppins-bold-extra-italic';
  src: url('assets/fonts/poppins/Poppins-ExtraBoldItalic.ttf');
}

@font-face {
  font-family: 'poppins-light-extra';
  src: url('assets/fonts/poppins/Poppins-ExtraLight.ttf');
}

@font-face {
  font-family: 'poppins-light-extra-italic';
  src: url('assets/fonts/poppins/Poppins-ExtraLightItalic.ttf');
}

@font-face {
  font-family: 'poppins-italic';
  src: url('assets/fonts/poppins/Poppins-Italic.ttf');
}

@font-face {
  font-family: 'poppins-light';
  src: url('assets/fonts/poppins/Poppins-Light.ttf');
}

@font-face {
  font-family: 'poppins-light-italic';
  src: url('assets/fonts/poppins/Poppins-LightItalic.ttf');
}

@font-face {
  font-family: 'poppins-medium';
  src: url('assets/fonts/poppins/Poppins-Medium.ttf');
}

@font-face {
  font-family: 'poppins-medium-italic';
  src: url('assets/fonts/poppins/Poppins-MediumItalic.ttf');
}

@font-face {
  font-family: 'poppins-regular';
  src: url('assets/fonts/poppins/Poppins-Regular.ttf');
}

@font-face {
  font-family: 'poppins-semibold';
  src: url('assets/fonts/poppins/Poppins-SemiBold.ttf');
}

@font-face {
  font-family: 'poppins-semibold-italic';
  src: url('assets/fonts/poppins/Poppins-SemiBoldItalic.ttf');
}

@font-face {
  font-family: 'poppins-thin';
  src: url('assets/fonts/poppins/Poppins-Thin.ttf');
}

@font-face {
  font-family: 'poppins-thin-italic';
  src: url('assets/fonts/poppins/Poppins-ThinItalic.ttf');
}

/* Hide the up and down icons */
input[type='number']::-webkit-inner-spin-button,
input[type='number']::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

[role='dialog'],
[data-baseweb='modal'] {
  z-index: 9;
}

[data-baseweb='popover'] {
  z-index: 100;
}

.btn-font-size-13 button,
.btn-font-size-13-update button {
  font-size: 13px;
}

.btn-font-size-13 > div > div,
.btn-font-size-13-update > div {
  display: flex;
  justify-content: center;
}

[data-baseweb='popover'] {
  z-index: 10;
}

.font-family-poppins {
  font-family: 'poppins-medium';
  box-sizing: border-box;
}

.font-family-poppins * {
  font-family: inherit;
  color: var(--black);
  box-sizing: border-box;
}

.font-family-poppins button:not(.button) {
  border-radius: 0;
  outline: 0;
  background-color: transparent;
  border: 0;
  cursor: pointer;
}

:root {
  --black: #1f203a;
  --blue: #303393;
  --lightRed: #f06548;
  --lightGray: rgba(48, 51, 147, 0.1);
  --bgGray: #ebebf5;
  --tableRowBg: #f4f5ff;
  --tableCellDarkGray: #d1d1e8;
}

.header-action-user-content:after {
  content: url('/assets/icons/userDropdownangle.svg');
  position: absolute;
  top: 2px;
  right: 0;
  height: 19px;
}

.module-service-box:hover {
  background-color: var(--bgGray);
}

.caret-icon {
  font-size: 15px; /* Adjust the size as needed */
  margin-bottom: 3px;
}

.custom-sidebar .menu a {
  display: flex;
  gap: 8px;
  align-items: center;
  text-decoration: none;
  padding: 12px 24px;
  transition: all 500ms ease;
  font-size: 14px;
  line-height: 21px;
  font-weight: 400;
}

.sidebar-memu-list,
.sidebar-memu-list ul {
  padding: 0;
  margin: 0;
  list-style-type: none;
}

.sidbar-link-active > a {
  background-color: var(--lightGray);
}

.sidbar-link-dropdown {
  position: relative;
}

.sidbar-link-dropdown:after {
  content: url('/assets/icons/sidebar-dropdown-arrow.svg');
  position: absolute;
  top: 13px;
  right: 24px;
}

.sidbar-link-dropdown:not(.sidbar-link-active):after {
  transform: rotate(180deg);
}

.list-normal {
  padding: 0;
  margin: 0;
  list-style-type: none;
}

.cogs-table {
  width: 100%;
  border-collapse: collapse;
  table-layout: fixed;
}

@font-face {
  font-family: 'Verdana';
  src: url('./assets/fonts/Verdana.woff2') format('woff2'),
    url('./assets/fonts/Verdana.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Tahoma';
  src: url('./assets/fonts/Tahoma.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

.number-cell {
  font-family: 'Verdana', 'Arial', sans-serif !important;
}

.cogs-table table tbody tr:nth-child(even) {
  background-color: #f9f9fc;
}

.cogs-table table tbody tr:hover {
  transition: 0.1s all ease-in;
  background-color: #e8e8ff;
}

.cogs-table td:not(.tdreset),
.cogs-table th:not(.tdreset) {
  border: 1px solid #dfdfed;
  font-size: 12px;
  line-height: 21px;
  color: var(--black);
}

// @media only screen and (max-width: 1600px) {
//   /* Applies only on screens smaller than 1024px */
//   .cogs-table-pattern td:not(.tdreset),
//   .cogs-table-pattern th:not(.tdreset) {
//     font-size: 10px !important; /* Smaller font size for small screens */
//   }
// }

.cogs-table td {
  font-weight: 400;
}

.cogs-table th {
  font-weight: 500;
  position: relative;
}

.cogs-table th,
.cogs-table td {
  // width: 120px;
  // min-width: 150px;
}

.cogs-table .percent-column {
  // width: 80px !important;
  // min-width: 110px !important;
}

// tr.second-row th {
//   width: 135px;
// }

// .cogs-table table thead tr:first-child>td:first-child,
// .cogs-table table tbody tr>td:first-child,
// .cogs-table>thead>tr:first-child>th:first-child {
//   width: 150px;
//   min-width: 150px;
// }

.cogs-table-pattern th,
.cogs-table-pattern td {
  // width: 160px;
  // min-width: 160px;
}

.cogs-table .remove-padding-border {
  padding: 0px !important;
  border: 0px !important;
}

.cogs-table > thead:not(.nested-data) {
  position: sticky;
  top: 0;
  z-index: 2;
}

.cogs-table > thead {
  // display: table;
  // width: 100vw;
}

.cogs-table-wrapper table table tbody {
  // width: 100vw;
}

.cogs-table-wrapper {
  overflow-x: auto;
  white-space: nowrap;
  margin-top: 27px;
  // max-height: 557px;
  overflow-y: auto;
  // width: 100%;
}

.cogs-table table tbody tr > td:not(:first-child),
.cogs-table table thead tr > td:not(:first-child) {
  text-align: right;
}

.cogs-table > thead tr:nth-child(1) > th:nth-child(odd) {
  background-color: #e8e8ff;
}

.cogs-table > thead tr:nth-child(1) > th:nth-child(even) {
  background-color: #eff0ff;
}

.cogs-table thead th {
  padding: 14px 12px 14px 12px;
  font-family: 'poppins-medium';
  text-align: center;
}

// .cogs-table tbody td:not(.tdreset),
// .cogs-table > thead tr:nth-child(3) th {
//   padding: 8px;
// }

.cogs-table tbody table thead tr {
  background-color: #d7d8e4;
}

// .cogs-table-wrapper table table tbody tr:nth-child(odd) td:nth-child(1) {
//   background-color: #fff;
// }

// .cogs-table-wrapper table.cogs-table-account table tbody tr td:nth-child(1) {
//   background-color: #d7d8e4;
// }

.cogs-table-wrapper table table.cogs-table-account tbody tr td:nth-child(1) {
  background-color: #f9f9fc;
}

// .cogs-table-wrapper table table tbody tr:hover td:nth-child(1) {
//   transition: 0.1s all ease-in;
//   background-color: #e8e8ff;
// }

.cogs-table.cogs-table-account thead tr .first-col,
.cogs-table tbody tr .first-col {
  width: 200px;
}

// .cogs-table-wrapper table table tbody tr:hover td:nth-child(1) {
//   background-color: unset;
// }

.cogs-table.nested-table-nested tbody tr:hover,
.cogs-table.nested-table-nested tbody tr:hover td:nth-child(1) {
  background-color: #c3c3ff;
}

.nested-data {
  cursor: pointer;
  user-select: none;
}

.nested-data tr:not(.active):hover,
.nested-data tr.active {
  background-color: var(--tableCellDarkGray);
}

span:hover .tooltip-content {
  visibility: visible;
  opacity: 1;
}

.nested-data
  tr:not(.active):hover
  .nested-table-nested
  .nested-data
  td.first-col {
  background-color: white;
}

.nested-data td {
  font-family: 'poppins-medium';
}

// .nested-data tr:not(.active) > td:first-child {
//   background-color: #ececf1;
// }

.cogs-table-wrapper table table thead tr:hover td:nth-child(1),
.cogs-table-wrapper table table thead tr.active td:nth-child(1) {
  background-color: var(--tableCellDarkGray);
}

// .cogs-table-wrapper table table tbody tr:hover td:nth-child(1) {
//   background-color: unset;
// }

.text-center {
  text-align: center;
}

.text-left {
  text-align: left;
}

.overflow-x-hidden {
  overflow-x: hidden;
}

.multi-selectbox {
  width: 100%;
}

.multi-selectbox [data-baseweb='tag'] {
  background-color: rgba(48, 51, 147, 0.1);
  font-size: 14px;
  line-height: 21px;
  font-weight: 400;
}

.multi-selectbox [data-baseweb='select'] > div > div:nth-child(2) {
  position: relative;
}

[aria-multiselectable='true'][data-baseweb='menu']
  li
  [aria-selected='true']:after {
  content: '✔';
  position: absolute;
  left: 16px;
  top: 50%;
  transform: translateY(-50%);
  width: 18px;
  height: 18px;
  border-radius: 4px;
  background-color: var(--blue);
  color: #fff;
  border: 2px solid var(--blue);
  transition: 0.3s all ease-in;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 13px;
}

[aria-multiselectable='true'][data-baseweb='menu'] li [aria-selected='true'] {
  color: var(--blue) !important;
  font-weight: 900;
}

li[role='option'][aria-selected='true'] > div[aria-selected='true'] {
  font-weight: 700;
}

.cogs-table-pattern td.w-320 {
  width: 320px;
  min-width: 320px;
}

.pt-30 {
  padding-top: 30px;
}

.nested-table {
  width: 100%;
  table-layout: fixed;
  border-collapse: collapse;
}

.sub-table > td {
  padding: 0px !important;
}

.cogs-table thead tr .first-col,
.cogs-table tbody tr .first-col {
  width: 150px;
}

//linked with 150px, because first col width is 150px
.nested-table tbody tr td,
.total-table tbody tr td {
  width: calc((100% - 150px) / 12);
}

.main-row td:first-child {
  background-color: var(--tableCellDarkGray);
}

.main-row td:not(:first-child) {
  text-align: right;
}

.main-row {
  cursor: pointer;
  background-color: #d7d8e4;
}

.main-row:hover {
  transition: 0.3s all ease-in;
  background-color: var(--tableCellDarkGray);
}

.total-table .active {
  background-color: var(--tableCellDarkGray);
}

.total-table .active td:not(:first-child) {
  text-align: right;
}

.cogs-table > td {
  font-weight: 500;
}

.week-dropdown svg:hover {
  cursor: pointer;
}

.cogs-table.nested-table-nested td:not(.tdreset),
.cogs-table.nested-table-nested th:not(.tdreset) {
  font-size: 12px;

  // @media only screen and (max-width: 1600px) {
  //   font-size: 10px !important;
  // }
}

.cogs-table.nested-table-nested > tr:nth-child(odd) {
  background-color: #e8e8ff;
}

.fixed-container {
  position: fixed;
  top: 0;

  height: 100%;
  overflow-y: auto;
  overflow-x: hidden;
  background-color: white;
  z-index: 1;
}

.datepicker-container input {
  background-color: white; /* Input background */
}
