@import "src/styles/colors";

.main-notification-wrapper {
    display: flex;
    align-items: center;
    gap: 10px;
    padding: 10px;
    margin: 5px;
    &-profile {
        width: 12%;

        &-img {
            width: 20px;
            &-wrapper {
                border-radius: 50%;
                padding: 16px;
                background-color: $color-light-gray;
                display: flex;
                justify-content: center;
                align-items: center;
            }
            &.requisition {
                filter: invert(55%) sepia(44%) saturate(3790%) hue-rotate(161deg) brightness(97%) contrast(101%);
            }
            &.order {
                filter: invert(56%) sepia(72%) saturate(2275%) hue-rotate(2deg) brightness(101%) contrast(106%);
            }
            &.invoice {
                filter: invert(50%) sepia(97%) saturate(350%) hue-rotate(117deg) brightness(90%) contrast(108%);
            }
            &.conflictinvoice {
                filter: invert(42%) sepia(59%) saturate(6650%) hue-rotate(335deg) brightness(100%) contrast(97%);
            }
            &.pricelist {
                filter: invert(41%) sepia(20%) saturate(1979%) hue-rotate(221deg) brightness(91%) contrast(85%);
            }
            &.template {
                filter: invert(51%) sepia(32%) saturate(1043%) hue-rotate(291deg) brightness(97%) contrast(93%);
            }
        }
        @media only screen and (max-width: 640px) {
            width: 14%;
        }
    }
    &-text {
        width: 75%;
        display: flex;
        flex-direction: column;
        &-heading {
            margin: 0;
            &-h6 {
                margin: 0;
                font-size: 15.4px;
                font-weight: 500;
                @media only screen and (max-width: 640px) {
                    font-size: 13.4px;
                }
            }
            &-span {
                margin: 0;
                margin-left: 10px;
                color: rgb(29, 29, 29);
                font-weight: 400;
                cursor: pointer;
            }
            &-time {
                margin: 0;
                margin-top: 5px;
                color: $color-dark-blue;
                font-size: 14px;
                @media only screen and (max-width: 640px) {
                    font-size: 12.4px;
                }
            }
        }
    }
    &-read {
        width: 5%;
        cursor: pointer;
        font-size: 12px;
        min-width: 75px;
        margin-right: -16px;
        &:hover {
            text-decoration: underline;
        }
    }
}
