@keyframes vibrateAnimation {
    0% { transform: translateX(0); }
    25% { transform: translateX(2px); }
    50% { transform: translateX(-2px); }
    75% { transform: translateX(2px); }
    100% { transform: translateX(0); }
  }

  .vibrate {
    animation: vibrateAnimation 0.3s ease;
  }