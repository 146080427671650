$breakpoints: (
  xxs: 420px,
  xs: 576px,
  sm: 768px,
  md: 992px,
  lg: 1200px,
  xl: 1440px,
  xxl: 1840px,
);

@mixin respond-below($breakpoint) {
  @if map-has-key($breakpoints, $breakpoint) {
    $breakpoint-value: map-get($breakpoints, $breakpoint);
    @media (max-width: ($breakpoint-value - 1)) {
      @content;
    }
  } @else {
    @warn "Invalid breakpoint: #{$breakpoint}.";
  }
}

@mixin respond-above($breakpoint) {
  @if map-has-key($breakpoints, $breakpoint) {
    $breakpoint-value: map-get($breakpoints, $breakpoint);
    @media (min-width: ($breakpoint-value - 1)) {
      @content;
    }
  } @else {
    @warn "Invalid breakpoint: #{$breakpoint}.";
  }
}
