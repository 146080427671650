@import 'src/styles/colors';

.create-order-list {
  background-color: $color-white;
  border-radius: 12px;
  box-shadow: 0px 0px 28px rgba(0, 0, 0, 0.02);
  padding: 14px;
  animation: fade-in 0.5s;

  .input-wrapper {
    flex: 1;

    .input-label {
      font-size: 14px;
      font-family: 'poppins-regular';
      color: $color-table-text;
    }
  }
  .button-wrapper {
    display: flex;
    justify-content: center;
    align-items: flex-end;
    height: 100%;
    @media only screen and (max-width: 540px) {
      justify-content: flex-start;
  }

    &.algin-center {
      align-items: center;
    }
  }
}

.hidden {
  display: none;
  animation: fade-out 0.5s;
}

.visible {
  display: block;
  animation: fade-in 0.5s;
}

.toggle-content {
  display: none;
  padding: 10px;
  animation: fade-in 0.5s;
}

@keyframes fade-in {
  from { opacity: 0; }
  to { opacity: 1; }
}

@keyframes fade-out {
  from { opacity: 1; }
  to { opacity: 0; }
}
.apply-btn{
  margin-top: auto;
  margin-left: 24px;
}