@import 'src/styles/colors';

.create-order-list {
  background-color: $color-white;
  border-radius: 12px;
  box-shadow: 0px 0px 28px rgba(0, 0, 0, 0.02);
  padding: 14px;

  .input-wrapper {
    flex: 1;

    .input-label {
      font-size: 14px;
      font-family: 'poppins-regular';
      color: $color-table-text;
    }
  }
  .invoice-button-wrapper {
    display: flex;
    justify-content: flex-start;
    align-items: flex-end;
    height: 100%;
    margin-left: -150px;
    @media only screen and (max-width: 540px) {
      justify-content: flex-start;
  }

    &.algin-center {
      align-items: center;
    }
  }
}
