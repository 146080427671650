@import 'src/styles/colors';

.custom-select {
  position: relative;
  width: 200px;
  font-size: 16px;
  background-color: #fff !important;
  border-radius: 0.5rem;
  border: 3px solid #eee;
  @media only screen and (max-width: 540px) {
    width: 100%;
  }

  .select-trigger {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 48px;
    padding: 0 16px;
    border-radius: 4px;
    cursor: pointer;
    background-color: #fff;

    span:first-of-type {
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      color: $color-table-text;
    }
  }

  .options {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    position: absolute;
    top: 100%;
    left: 0;
    z-index: 11;
    width: 100%;
    max-height: 300px;
    overflow-y: auto;
    background-color: $color-white;
    border-top: none;
    border-radius: 0 0 4px 4px;
    box-shadow: rgba(0, 0, 0, 0.16) 0px 4px 16px;

    &:hover {
      background-color: $color-white; // Same as the container's background
    }

    .option {
      font-size: 12px;
      display: flex;
      align-items: center;
      height: 40px;
      padding: 0 10px;
      cursor: pointer;
      color: $color-table-text;

      input[type='checkbox'] {
        appearance: none;
        position: relative;
        display: inline-block;
        width: 18px;
        height: 18px;
        border: 1px solid $color-medium-gray;
        border-radius: 4px;
        cursor: pointer;
        outline: none;
        transition: all 0.2s;
        margin-right: 8px;

        &:before {
          content: '';
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
          width: 12px;
          height: 12px;
          border-radius: 2px;
          background-color: transparent;
          transition: all 0.2s;
        }

        &:checked:before {
          background-color: $color-primary3;
        }

        &:hover:not(:checked) {
          background-color: $color-light-gray;
        }

        &:focus:not(:checked) {
          box-shadow: 0 0 0 3px rgba($color-dark-blue, 0.3);
        }

        &:checked:focus:before {
          box-shadow: 0 0 0 3px rgba($color-dark-blue, 0.3);
        }
      }
    }
  }

  &.disabled {
    pointer-events: none;
    opacity: 0.5;
    background-color: #eee;
    cursor: not-allowed;

    .select-trigger {
      cursor: not-allowed;
    }
  }

  &.changed {
    background-color: #a5f3fc !important;

    .select-trigger {
      background-color: #a5f3fc !important;
    }
  }
}

.select-all {
  font-weight: 800;
}
