@import 'src/styles/colors';

.report-list {
  background-color: $color-white;
  box-shadow: 0px 0px 28px rgba(0, 0, 0, 0.02);
  padding: 4px 8px 8px 8px;

  &-rows {
    @media only screen and (max-width: 540px) {
      overflow-x: scroll;
    }
  }
  &-test {
    background-color: red;
  }
  &-header {
    display: flex;
    flex-wrap: wrap;
    padding: 0px;
    @media only screen and (max-width: 540px) {
      overflow: scroll;
      flex-wrap: nowrap;
    }

    &-column {
      display: flex;
      flex: 1;
      justify-content: left;
      align-items: left;
      padding: 16px 8px 8px 4px;
      background-color: $report-table-bg;
      @media only screen and (max-width: 540px) {
        padding: 12px 6px 6px 0px;
      }
      &.half-flex {
        flex: 0.5;
      }
      &.flex-end {
        justify-content: flex-end;
      }
      &__label {
        font-size: 14px;
        font-family: 'poppins-regular';
        color: $color-white;
        margin-right: 4px;
        font-weight: 900;
        @media only screen and (max-width: 540px) {
          font-size: 12px;
          width: max-content;
        }
      }
      &__icon {
        width: 12px;
        height: 12px;
      }
    }
    &-column1 {
      padding-left: 200px;
      display: flex;
      flex: 1;
      justify-content: left;
      align-items: left;
      padding: 16px 8px 8px 4px;
      background-color: $report-table-bg;
      @media only screen and (max-width: 540px) {
        padding: 12px 6px 6px 0px;
      }
      &.half-flex {
        flex: 0.5;
      }
      &.flex-end {
        justify-content: flex-end;
      }
      &__label {
        font-size: 14px;
        font-family: 'poppins-regular';
        color: $color-white;
        margin-right: 4px;
        font-weight: 900;
        @media only screen and (max-width: 540px) {
          font-size: 12px;
          width: max-content;
        }
      }
      &__icon {
        width: 12px;
        height: 12px;
      }
    }

    &-columnNumber {
      display: flex;
      flex: 1;
      justify-content: right;
      align-items: right;
      padding: 16px 8px 8px 4px;
      background-color: $report-table-bg;
      @media only screen and (max-width: 540px) {
        padding: 12px 6px 6px 0px;
      }
      &.half-flex {
        flex: 0.5;
      }

      &.flex-end {
        justify-content: flex-end;
      }
      &__label {
        font-size: 14px;
        font-family: 'poppins-regular';
        color: $color-white;
        margin-right: 4px;
        font-weight: 900;
        @media only screen and (max-width: 540px) {
          font-size: 12px;
          width: max-content;
        }
      }
      &__icon {
        width: 12px;
        height: 12px;
      }
    }
  }
  &-row {
    display: flex;
    margin-top: 0px;
    padding: 0px;
    @media only screen and (max-width: 540px) {
      width: max-content;
    }
    &:nth-child(even) {
      background-color: $report-table-even-row;
    }

    &-column {
      display: flex;
      flex: 1;
      justify-content: left;
      align-items: left;
      padding: 16px 8px 8px 4px;

      &.half-flex {
        flex: 0.5;
      }
      &.flex-end {
        justify-content: flex-end;
      }
      &-select {
        background-color: transparent;
        border: none;
        outline: none;
      }

      &__label {
        font-size: 14px;
        font-family: 'poppins-regular';
        color: $color-table-text;
        margin-right: 4px;
        @media only screen and (max-width: 540px) {
          font-size: 12px;
        }
        &.half-flex {
          flex: 0.5;
        }
      }
    }
    &-column1 {
      display: flex;
      flex: 1;
      justify-content: left;
      align-items: left;
      padding: 16px 8px 8px 4px;

      &.half-flex {
        flex: 0.5;
      }
      &.flex-end {
        justify-content: flex-end;
      }
      &-select {
        background-color: transparent;
        border: none;
        outline: none;
      }

      &__label {
        width: 30%;
        font-size: 14px;
        font-family: 'poppins-regular';
        color: $color-table-text;
        margin-right: 4px;
        @media only screen and (max-width: 540px) {
          font-size: 12px;
        }
        &.half-flex {
          flex: 0.5;
        }
      }
    }
    &-columnNumber {
      display: flex;
      flex: 1;
      justify-content: right;
      align-items: right;
      padding: 16px 8px 8px 4px;

      &.half-flex {
        flex: 0.5;
      }
      &.flex-end {
        justify-content: flex-end;
      }
      &-select {
        background-color: transparent;
        border: none;
        outline: none;
      }

      &__label {
        font-size: 14px;
        font-family: 'poppins-regular';
        color: $color-table-text;
        margin-right: 4px;
        @media only screen and (max-width: 540px) {
          font-size: 12px;
        }
      }
    }
  }
}

[data-attr-sno='true'],
[data-attr-sno-header='true'] {
  flex: 0.3;
  justify-content: end;
}

[data-attr-no-items-header='true'] {
  flex: 0.7;
}

[data-attr-no-items='true'] {
  flex: 0.7;
  justify-content: flex-end;
}

[data-attr-no-datetime='true'],
[data-attr-no-datetime-header='true'] {
  flex: 1.4;
}

[data-attr-no-noofitems='true'],
[data-attr-no-noofitems-header='true'] {
  flex: 0.5 !important;
}
[data-attr-no-type='true'],
[data-attr-no-type-header='true'] {
  flex: 0.4;
}
