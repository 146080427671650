@import 'src/styles/colors';

.pie-chart-reports {
  padding: 16px;
  min-width: 400px;
  height: 600px;
  margin-bottom: 60px;
  flex: 1;
}

.chart-title {
  text-align: center;
  color: $color-dark-blue;
}
