@import "src/styles/colors";

.report-list {
    background-color: $color-white;
    box-shadow: 0px 0px 28px rgba(0, 0, 0, 0.02);
    padding: 4px 8px 8px 8px;

    &-rows {
        @media only screen and (max-width: 540px) {
            overflow-x: scroll;
        }
    }
    &-test {
       background-color: red;
    }
    &-header {
        display: flex;
        flex-wrap: wrap;
        padding: 0px;
        @media only screen and (max-width: 540px) {
            overflow: scroll;
            flex-wrap: nowrap;
        }

        &-column {
            display: flex;
            flex: 1;
            justify-content: left;
            align-items: left;
            padding: 16px 8px 8px 4px;
            background-color: $report-table-bg;
            @media only screen and (max-width: 540px) {
                padding: 12px 6px 6px 0px;
            }
            &.half-flex {
                flex: 0.5;
            }
            &.flex-end {
                justify-content: flex-end;
            }
            &__label {
                font-size: 14px;
                font-family: "poppins-regular";
                color: $color-white;
                margin-right: 4px;
                font-weight: 900;
                @media only screen and (max-width: 540px) {
                    font-size: 12px;
                    width: max-content;
                }
            }
            &__icon {
                width: 12px;
                height: 12px;
            }
        }
        &-columnNumber {
            display: flex;
            flex: 1;
            justify-content: right;
            align-items: right;
            padding: 16px 8px 8px 4px;
            background-color: $report-table-bg;
            @media only screen and (max-width: 540px) {
                padding: 12px 6px 6px 0px;
            }
            &.half-flex {
                flex: 0.5;
            }
            &.flex-end {
                justify-content: flex-end;
            }
            &__label {
                font-size: 14px;
                font-family: "poppins-regular";
                color: $color-white;
                margin-right: 4px;
                font-weight: 900;
                @media only screen and (max-width: 540px) {
                    font-size: 12px;
                    width: max-content;
                }
            }
            &__icon {
                width: 12px;
                height: 12px;
            }
        }
    }
    &-row {
        display: flex;
        margin-top: 0px;
        padding: 0px;
        @media only screen and (max-width: 540px) {
            width: max-content;
        }
        &:nth-child(even) {
            background-color: $report-table-even-row;
        }

        &-column {
            display: flex;
            flex: 1;
            justify-content: left;
            align-items: left;
            padding: 16px 8px 8px 4px;

            &.half-flex {
                flex: 0.5;
            }
            &.flex-end {
                justify-content: flex-end;
            }
            &-select {
                background-color: transparent;
                border: none;
                outline: none;
            }

            &__label {
                font-size: 14px;
                font-family: "poppins-regular";
                color: $color-table-text;
                margin-right: 4px;
                @media only screen and (max-width: 540px) {
                    font-size: 12px;
                }
            }
        }
        &-columnNumber {
            display: flex;
            flex: 1;
            justify-content: right;
            align-items: right;
            padding: 16px 8px 8px 4px;

            &.half-flex {
                flex: 0.5;
            }
            &.flex-end {
                justify-content: flex-end;
            }
            &-select {
                background-color: transparent;
                border: none;
                outline: none;
            }

            &__label {
                font-size: 14px;
                font-family: "poppins-regular";
                color: $color-table-text;
                margin-right: 4px;
                @media only screen and (max-width: 540px) {
                    font-size: 12px;
                }
            }
        }
    }
}

@media screen {
	.print-list {
		display: none
	}
    .report-list {
		display: block
	}
}

@media print {
  .header{
    /* Define styles for the header and footer, e.g., background color, font size, etc. */
    /* Ensure that the height of the header and footer is considered in your layout */
    position: fixed;
    left: 0;
    right: 0;
    margin-bottom: 20px;
  }
//   .footer {
//     position: fixed;
//     bottom: 20px; /* Adjust the distance from the bottom of the page */
//     right: 20px; 
//   }

  .header {
    top: -20px;
    margin-bottom: 10px
  }

  .footer {
    bottom: 0;
  }

  .report-list{
    margin-top: 20px;
  }

//   .footer::after {
//     counter-increment: page;
//     content: counter(page) " of " counter(pages);
//   }

  .print-content {
    /* Add styles for the main content */
  }

  /* Define page breaks for sections */
  section {
    page-break-after: always;
  }
}
