$color-primary1: #09b08c;
$color-primary2: #063360;
$color-primary3: #0041a1;
$color-background: #f4f7fa;
$color-dark-blue: #445578;

$color-light-blue: #00aeef;
$color-white: #ffffff;
$color-black: #000000;
$color-red: #e81313;

$color-lighter-gray: #f5f5f5;
$color-light-gray: #ececec;
$color-medium-gray: #9f9f9f;
$color-darker-gray: #5e5e5e;
$color-dark-gray: #505050;
$color-darkest-gray: #797979;
$color-background-gray: #e5e5e5;

$color-light-green: #22d822;
$color-dark-green: #1abc9c;
$color-light-orange: #fdaa81;
$color-table-text: #62636a;
$report-table-bg: #063360;
$report-table-even-row: #deeeff;
$green-gradient: linear-gradient(to bottom right, #3ed039e7, #42dc10);
$orange-gradient: linear-gradient(to bottom right, #f9ad21f6, #f9ac21eb);
$purple-gradient: linear-gradient(to bottom right, #c90a6d, #ff48a0);
$red-gradient: linear-gradient(to bottom right, #c90a0a, #ee3124);
$blue1-gradient: linear-gradient(to bottom right, #5e5aec, #3f9efc);
$blue2-gradient: linear-gradient(to bottom right, #6452e9, #639ff9);
$light-blue-gradient: linear-gradient(to bottom right, #48a9fe, #0beef9);
$blue-gradient: linear-gradient(to bottom right, #09c6f9, #045de9);
$silver-gradient: linear-gradient(to bottom right, #9fa4c4, #b3cdd1);
