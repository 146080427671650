@import '../../../../../styles/mixins';

.sidebarRootWrapper {
  position: fixed;
  padding: 85px 4px 4px 4px;
  // height: 100vh;
  transition: all 0.3s ease-in-out;
  z-index: 100;
  overflow-y: auto;
  scrollbar-width: thin;

  @include respond-below(lg) {
    background: white;
  }

  @include respond-below(xxs) {
    padding: 70px 0px 0px 0px;
  }

  .navigation-links-wrapper {
    padding: 16px 2px 0px;
    border-radius: 20px;
    box-shadow: 0px 0px 50px rgba(0, 0, 0, 0.05);
    background-color: white;
    height: calc(100vh - 125px);
    // height: 100%;
    overflow-y: auto;
    scrollbar-width: thin;
    z-index: 150;

    @media (max-width: 1000px) {
      padding: 0px 16px 0px 0px;
      box-shadow: none;
    }

    @media screen and (max-width: 768px) {
      width: 72px;
    }

    ::-webkit-scrollbar {
      width: 8px;
    }
    ::-webkit-scrollbar-track {
      background: #f1f1f1;
      border-radius: 10px;
    }
    ::-webkit-scrollbar-thumb {
      background-color: #c1c1c1;
      border-radius: 10px;
      border: 2px solid #f1f1f1;
    }
    ::-webkit-scrollbar-thumb:hover {
      background-color: red;
    }
  }
}
.sidebarLabel {
  pointer-events: none;
  transition: all 400ms ease;
  @media screen and (max-width: 1200px) {
    display: none;
  }
}

.menu-bar {
  height: 88vh;
  margin-top: 2rem;
  display: flex;
  flex-direction: column;
  gap: 15px;
  font-size: 1rem;
  position: relative;
  overflow-y: auto; /* Add scrollbar only when needed */
  overflow-x: hidden;
}
