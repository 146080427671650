@import 'src/styles/colors';

.purchase-contribution {
  background-color: $color-white;
  border-radius: 12px;
  box-shadow: 0px 0px 28px rgba(0, 0, 0, 0.02);
  padding: 14px;

  .input-wrapper {
    flex: 1;

    .input-label {
      font-size: 14px;
      font-family: 'poppins-regular';
      color: $color-table-text;
    }
  }
  .equal-width {
    flex: 1;
  }
  .btn-container {
    padding-top: 12px;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    gap: 14px;
  }
}
