@import "src/styles/colors";

.main-wrapper {
    background: $color-white;
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
    position: fixed;
    top: 65px;
    right: 50px;
    height: 85vh;
    padding: 8px 0px;
    transition: all 0.5s ease-in-out;
    width: 500px;
    border-radius: 4px;
    overflow-y: scroll;
    z-index: 1000;
    &::-webkit-scrollbar {
        width: 8px;
        padding: 2px;
    }

    &::-webkit-scrollbar-track {
        background-color: #f1f1f1;
    }

    &::-webkit-scrollbar-thumb {
        background-color: #ccc;
        border-radius: 10px;
    }

    &::-webkit-scrollbar-thumb:hover {
        background-color: #afaeae;
    }

    @media only screen and (max-width: 640px) {
        width: 96.5%;
        left: 0;
        padding: 10px;
        right: 0;
        height: 100vh;
    }

    &-top-header {
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 100%;
        font-family: poppins-medium;
        position: relative;
        &-heading {
            font-size: 22px;
            font-weight: 500;
            font-family: poppins-medium;
            text-transform: capitalize;
            margin-left: 16px;
            @media only screen and (max-width: 640px) {
                font-size: 19px;
            }
        }
        &-menu {
            width: 25px;
            cursor: pointer;
        }
    }
    &-notifications {
        &-heading {
            margin: 0;
            margin-left: 15px;
            font-size: 15px;
            color: $color-dark-blue;
            font-family: poppins-medium;
            font-weight: 600;
            text-transform: uppercase;
            @media only screen and (max-width: 640px) {
                font-size: 12.4px;
            }
        }
    }
}

.mark-read {
    position: absolute;
    z-index: 1000;
    right: 20px;
    font-weight: 500;
    margin-top: 0px;
    font-size: 15px;
    color: $color-dark-blue;
    font-family: poppins-medium;
    cursor: pointer;
    @media only screen and (max-width: 640px) {
        font-size: 11.6px;
    }
}
